<script lang="ts" setup>
import { toRefs } from 'vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

type Props = {
  images: ImageLink[];
  itemsToShow?: number;
}

const props = withDefaults(defineProps<Props>(), {
  itemsToShow: 1
})

const { images, itemsToShow } = toRefs(props);

</script>

<template>
<div class="simple-carousel">
  <carousel
    :autoplay="2500"
    pause-autoplay-on-hover
    :itemsToShow="itemsToShow"
    wrap-around
    :transition="250"
  >
    <slide v-for="({ alt, href, src }, index) in images" :key="index">
      <a :href="href">
        <img :src="src" :alt="alt">
      </a>
    </slide>
    <template #addons>
      <pagination />
    </template>
  </carousel>
</div>
</template>
